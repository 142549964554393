import * as React from "react"
import Container from "@mui/material/Container";
import {StaticImage} from "gatsby-plugin-image";
import {Box, Button, Grid} from "@mui/material";
import Typography from "@mui/material/Typography";
import {graphql, Link} from "gatsby";

import {Slogan} from "../components/typography";
import Prijslijst from "../components/prijslijst";
import {sectionGridSpacing, sectionPadding} from "../theme";
import {Bouwnummer} from "../graphqlTypes";
import Situatie from "../components/situatie";
import Gevelaanzichten from "../components/gevelaanzichten";
import {Maps} from "../components/map";

interface Props {
    data: PageQueryData
}
export default ({data}: Props) => {
    return (
        <article>
            <StaticImage
                src={'../images/sfeer/0.jpg'}
                alt={'water'}
                layout={'fullWidth'}
                aspectRatio={1370/405}
            />
            <Box
                paddingY={sectionPadding}
            >
                <Container>
                    <Grid
                        container
                        spacing={sectionGridSpacing}
                    >
                        <Grid item xs={12} md={5}>
                            <Box
                                marginBottom={2}
                            >
                                <Slogan>
                                    Een heerlijk nieuw begin
                                </Slogan>
                            </Box>
                            <Typography
                                variant={'h2'}
                            >
                                Daverhof maakt al je verwachtingen waar
                            </Typography>
                            <Typography paragraph>
                                De rijke geschiedenis die in het dorpsgezicht van Kerk-Avezaath terug te vinden is, zorgt voor een vriendelijke uitstraling. Wie het dorp binnenrijdt, ziet het meteen: alles is gericht op aangenaam dorps wonen. De vele groen opgezette straten nodigen zowel jonge avonturiers als oudere levensgenieters uit zich hier te settelen.
                            </Typography>
                            <Typography variant={'h6'}>
                                Fraaie woonmix
                            </Typography>
                            <Typography paragraph>
                                Om in deze behoefte te voorzien krijgt Daverhof een variëteit aan woningen. Het plan bestaat uit 9 eengezinswoningen en 11 levensloopbestendige woningen.
                            </Typography>
                            <Typography paragraph>
                                Elke woning heeft een ruime achtertuin en een open woonkeuken waardoor het buitenleven door de openslaande tuindeuren zo naar binnen wordt gehaald.
                            </Typography>
                            <Typography paragraph>
                                Eenheid in kleur en materiaalgebruik en klassieke opbouw van gevels roepen sferen op van het aloude tuindorp. Een mooie mix van nostalgie en woonkwaliteit van nu.
                            </Typography>
                            <Button
                                component={Link}
                                variant={'contained'}
                                to={'/woningen'}
                            >
                                Bekijk de woningen
                            </Button>
                        </Grid>
                        <Grid item xs={12} md={7}>
                            <Box
                                marginBottom={2}
                            >
                                <StaticImage
                                    src={'../images/impressies/Blok-6.jpg'}
                                    alt={'blok 6'}
                                    layout={'fullWidth'}
                                    aspectRatio={631/291}
                                />
                            </Box>
                            <Maps />
                        </Grid>
                    </Grid>
                </Container>
            </Box>
            <Box
                component={'section'}
                paddingY={sectionPadding}
            >
                <Container>
                    <Grid
                        container
                        spacing={sectionGridSpacing}
                        direction={'row-reverse'}
                        alignItems={'center'}
                    >
                        <Grid
                            item
                            xs={12}
                            md={5}
                        >
                            <Typography
                                color={'primary.main'}
                                variant={'h6'}
                            >
                                Duurzaam Daverhof
                            </Typography>
                            <Typography paragraph>
                                Met de gastvrije steden Buren en Tiel op nog geen 10 minuten rijafstand heb je praktisch alles tot je beschikking. Sfeervolle winkelstraatjes, restaurants en cafés, fraaie parken en stadstuinen zijn hier allemaal te vinden.
                            </Typography>
                            <Typography paragraph>
                                Voor een compleet pakket aan dagelijkse boodschappen kun je in Tiel terecht bij winkelcentra als Passewaaij en Westlede. Hier is niet alleen een verscheidenheid aan supermarkten aanwezig, maar ook winkels als een dierenspeciaalzaak, bloemist, drogisterij en een boekhandel. Sporten kan je in Kerk-Avezaath in overvloed. Denk aan voetballen bij de Teisterbanders, tennis bij LTC Kellendonck of gymnastiek en dansen bij AGA.
                            </Typography>
                        </Grid>
                        <Grid item xs={12} md={7}>
                            <Grid
                                container
                                spacing={2}
                            >
                                <Grid item xs={5}>
                                    <StaticImage
                                        src={'../images/sfeer/student-houses-picture-id1227144603.png'}
                                        alt={'sfeer'}
                                        aspectRatio={278/378}
                                    />
                                </Grid>
                                <Grid item xs={7}>
                                    <StaticImage
                                        src={'../images/sfeer/zonnepanelen_kopen.png'}
                                        alt={'sfeer'}
                                        aspectRatio={333/378}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Container>
            </Box>
            <Box
                component={'section'}
                paddingY={sectionPadding}
                sx={{
                    backgroundColor: 'secondary.light'
                }}
            >
                <Container>
                    <Typography
                        variant={'h2'}
                    >
                        Beschikbaarheid
                    </Typography>
                    <Prijslijst bouwnummers={data.allBouwnummer.nodes} />
                </Container>
            </Box>
            <Box
                component={'section'}
                paddingY={sectionPadding}
            >
                <Container>
                    <Typography
                        variant={'h2'}
                        textAlign={'center'}
                    >
                        <Situatie />
                    </Typography>
                </Container>
            </Box>
            <Gevelaanzichten />
        </article>
    )
}

interface PageQueryData {
    allBouwnummer: {
        nodes: Bouwnummer[]
    }
}

export const pageQuery = graphql`
    query {
        allBouwnummer {
            nodes {
                ...BouwnummerArea
                ...Bouwnummer
            }
        }
    }
`
