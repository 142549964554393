import TableContainer from '@mui/material/TableContainer';
import React, {useState} from 'react'
import {Bouwnummer} from "../../graphqlTypes";
import {Paper, Table, TableBody, TableCell, TableHead, TableRow} from "@mui/material";
import { columns } from './columns';
import BouwnummerModal from "../bouwnummer/modal";

interface Props {
    bouwnummers: Bouwnummer[]
}
const Prijslijst = ({bouwnummers}: Props) => {

    const [selectedBouwnummer, setSelectedBouwnummer] = useState<Bouwnummer | null>()

    return (
        <>
            <TableContainer
                component={Paper}
                elevation={0}
            >
                <Table
                    sx={{ minWidth: 650 }}
                    aria-label="prijslijst"
                    size={'small'}
                >
                    <TableHead>
                        <TableRow>
                            {columns.map(column =>
                                <TableCell
                                    key={column.name}
                                    sx={{
                                        backgroundColor: 'transparent',
                                        color: 'primary.main'
                                    }}
                                >
                                    {column.name}
                                </TableCell>
                            )}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {bouwnummers.map((row) => (
                            <TableRow
                                hover
                                key={row.id}
                                onClick={() => setSelectedBouwnummer(row)}
                            >
                                {columns.map(column =>
                                    <TableCell align={"left"} key={column.name}>
                                        {column.format(row)}
                                    </TableCell>
                                )}
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            <BouwnummerModal
                bouwnummer={selectedBouwnummer}
                handleClose={() => setSelectedBouwnummer(null)}
            />
        </>
    )
}

export default Prijslijst