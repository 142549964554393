import React from "react"
import Prijs from '../prijs'
import Oppervlakte from "../oppervlakte"
import { statusMap } from "../../utils/utils"
import {Bouwnummer} from "../../graphqlTypes";
import {graphql} from "gatsby";

export const columns = [
    {
        name: 'Nr.',
        format: (bouwnummer: Bouwnummer) => bouwnummer.diversen.diversen.bouwnummer,
    },
    {
        name: 'Blok.',
        format: (bouwnummer: Bouwnummer) => bouwnummer.bouwtype.algemeen.omschrijving,
    },
    {
        name: 'Perceel',
        format: (bouwnummer: Bouwnummer) => bouwnummer.detail?.kadaster?.[0].kadastergegevens.oppervlakte && <Oppervlakte oppervlakte={bouwnummer.detail.kadaster[0].kadastergegevens.oppervlakte} />,
    },
    {
        name: 'Woonopp.',
        format: (bouwnummer: Bouwnummer) => bouwnummer.algemeen.woonoppervlakte && <Oppervlakte oppervlakte={bouwnummer.algemeen.woonoppervlakte} />,
    },
    {
        name: 'V.O.N.',
        format: (bouwnummer: Bouwnummer) => bouwnummer.financieel.overdracht.koopprijs && <Prijs prijs={bouwnummer.financieel.overdracht.koopprijs} />,
    },
    {
        name: 'Status',
        format: (bouwnummer: Bouwnummer) => bouwnummer.financieel.overdracht.status && statusMap[bouwnummer.financieel.overdracht.status]['naam'],
    },
];

export const query = graphql`
    fragment BouwnummerPrijslijst on Bouwnummer {
        ...Bouwnummer
    }
`
