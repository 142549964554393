import * as React from "react"
import Typography from "@mui/material/Typography";
import {StaticImage} from "gatsby-plugin-image";

const GevelaanzichtBlok1 = () => {
    return (
        <>
            <Typography
                variant={'h3'}
            >
                Blok 1
            </Typography>
            <StaticImage
                src={'../../images/gevelaanzichten/BLOK 1.png'}
                alt={'blok 1'}
                aspectRatio={398/263}
                height={263}
            />
        </>
    )
}

export default GevelaanzichtBlok1