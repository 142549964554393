import * as React from "react"
import Typography from "@mui/material/Typography";
import {StaticImage} from "gatsby-plugin-image";

const GevelaanzichtBlok2En3 = () => {
    return (
        <>
            <Typography
                variant={'h3'}
                flexGrow={1}
            >
                Blok 2 & 3
            </Typography>
            <StaticImage
                src={'../../images/gevelaanzichten/blok 2-3.png'}
                alt={'blok 2 en 3'}
                aspectRatio={680/211}
                height={211}
            />
        </>
    )
}

export default GevelaanzichtBlok2En3