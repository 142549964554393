import * as React from "react"
import {Box, Grid} from "@mui/material";
import {sectionPadding} from "../theme";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import {StaticImage} from "gatsby-plugin-image";
import GevelaanzichtBlok1 from "./gevelaanzichten/blok1";
import GevelaanzichtBlok2En3 from "./gevelaanzichten/blok2-3";
import GevelaanzichtBlok4 from "./gevelaanzichten/blok4";
import GevelaanzichtBlok5 from "./gevelaanzichten/blok5";
import GevelaanzichtBlok6 from "./gevelaanzichten/blok6";

const Gevelaanzichten = () => {
    return (
        <Box
            paddingY={sectionPadding}
            sx={{
                backgroundColor: 'secondary.light',
            }}
        >
            <Container>
                <Typography
                    variant={'h2'}
                    textAlign={'center'}
                >
                    Gevelaanzichten
                </Typography>
                <Grid
                    container
                    spacing={3}
                    alignItems={'stretch'}
                >
                    <Grid
                        item
                        xs={12}
                        md={5}
                    >
                        <GevelaanzichtBlok1 />
                    </Grid>
                    <Grid
                        item
                        xs={12}
                        md={7}
                        display={'flex'}
                        flexDirection={'column'}
                    >
                        <GevelaanzichtBlok2En3 />
                    </Grid>
                    <Grid item xs={12} md={5}>
                        <GevelaanzichtBlok4 />
                    </Grid>
                    <Grid
                        item
                        xs={12}
                        md={7}
                        display={'flex'}
                        flexDirection={'column'}
                    >
                        <GevelaanzichtBlok5 />
                    </Grid>
                    <Grid item xs={12} md={7}>
                        <GevelaanzichtBlok6 />
                    </Grid>
                </Grid>
            </Container>
        </Box>
    )
}

export default Gevelaanzichten