export interface ConditionalWrapperProps {
  condition?: string | null;
  wrapper: any;
  children: any;
}

export const bouwnummerSort = (a: any, b: any) => {
  return +a.diversen.diversen.bouwnummer > +b.diversen.diversen.bouwnummer ? 1 : -1
}

export const ConditionalWrapper = ({ condition, wrapper, children }: ConditionalWrapperProps) =>
  condition ? wrapper(children) : children

export interface Status {
  naam: string;
  variant: 'secondary' | 'error' | 'warning' | 'success';
}

type StatusMap = Record<string, Status>

export const statusMap: StatusMap = {
  'PROSPECT': { naam: 'Prospect', variant: 'secondary' },
  'IN_AANMELDING': { naam: 'In aanmelding', variant: 'secondary' },
  'BESCHIKBAAR': { naam: 'Beschikbaar', variant: 'secondary' },
  'ONDER_BOD': { naam: 'Onder bod', variant: 'warning' },
  'ONDER_OPTIE': { naam: 'Onder optie', variant: 'warning' },
  'VERKOCHT_ONDER_VOORBEHOUD': { naam: 'Verkocht onder voorbehoud', variant: 'error' },
  'VERHUURD_ONDER_VOORBEHOUD': { naam: 'Verhuurd onder voorbehoud', variant: 'error' },
  'VERKOCHT': { naam: 'Verkocht', variant: 'error' },
  'VERHUURD': { naam: 'Verhuurd', variant: 'error' },
  'GEVEILD': { naam: 'Geveild', variant: 'error' },
  'INGETROKKEN': { naam: 'Ingetrokken', variant: 'error' },
  'GEANNULEERD': { naam: 'Geannuleerd', variant: 'error' },
  'VERKOCHT_BIJ_INSCHRIJVING': { naam: 'Verkocht bij inschrijving', variant: 'error' }
};

export enum Daktype {
  LEISTEEN = 'Leisteen',
  RIET = 'Riet',
  ASBEST = 'Asbest',
  KUNSTSTOF = 'Kunststof',
  PANNEN = 'Pannen',
  BITUMINEUZE_DAKBEDEKKING = 'Bitumineus',
  METAAL = 'Metaal',
  OVERIG = 'Overig',
}

type WoonhuistypeMap = Record<string, string>

export const woonhuistypeMap: WoonhuistypeMap = {
  VRIJSTAANDE_WONING: 'Vrijstaande woning',
  GESCHAKELDE_WONING: 'Rijwoning',
  TWEE_ONDER_EEN_KAPWONING: 'twee-onder-één kap woning',
  TUSSENWONING: 'Tussenwoning',
  HOEKWONING: 'Hoekwoning',
  EINDWONING: 'Eindwoning',
  HALFVRIJSTAANDE_WONING: 'Halfvrijstaande woning',
  GESCHAKELDE_TWEE_ONDER_EEN_KAPWONING: 'Geschakelde twee-onder-één kap woning',
  VERSPRINGEND: 'Verspringend'
}

type KoopconditieMap = Record<string, string>

export const koopconditieMap: KoopconditieMap = {
 'KOSTEN_KOPER': 'k.k.',
 'VRIJ_OP_NAAM': 'v.o.n.',
}

export enum Ligging {
 NOORD = 'Noord',
 NOORDOOST = 'Noord-Oost',
 OOST = 'Oost',
 ZUIDOOST = 'Zuid-Oost',
 ZUID = 'Zuid',
 ZUIDWEST = 'Zuidwest',
 WEST = 'West',
 NOORDWEST = 'Noord-West',
}