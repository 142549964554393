import * as React from "react"
import Typography from "@mui/material/Typography";
import {StaticImage} from "gatsby-plugin-image";

const GevelaanzichtBlok5 = () => {
    return (
        <>
            <Typography
                variant={'h3'}
                flexGrow={1}
            >
                Blok 5
            </Typography>
            <StaticImage
                src={'../../images/gevelaanzichten/blok 5.png'}
                alt={'blok 5'}
                aspectRatio={680/259}
                height={259}
            />
        </>
    )
}

export default GevelaanzichtBlok5