import * as React from "react"
import Typography from "@mui/material/Typography";
import {StaticImage} from "gatsby-plugin-image";

const GevelaanzichtBlok4 = () => {
    return (
        <>
            <Typography
                variant={'h3'}
                flexGrow={1}
            >
                Blok 4
            </Typography>
            <StaticImage
                src={'../../images/gevelaanzichten/BLOK 4.png'}
                alt={'blok 4'}
            />
        </>
    )
}

export default GevelaanzichtBlok4