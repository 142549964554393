import React from 'react'
import {GoogleMap, LoadScript, Marker, Polygon} from "@react-google-maps/api"
import {graphql, useStaticQuery} from "gatsby";

const coords = [
    {lat: 51.896706, lng: 5.381691},
    {lat: 51.896957, lng: 5.382686},
    {lat: 51.896721, lng: 5.382784},
    {lat: 51.896661, lng: 5.382559},
    {lat: 51.89633857129805, lng: 5.382731536619885},
    {lat: 51.896007539271636, lng: 5.3819805181137506},
]

const position = {
    lat: 51.896520637872996,
    lng: 5.382302383187809
}

export const Maps = () => {

    const data = useStaticQuery(graphql`
        query {
            logo: file(relativePath: {eq: "logo.png"}) {
                childImageSharp {
                    gatsbyImageData(width: 20, quality: 100, layout: FIXED)
                }
            }
        }
    `)
    return (
        <LoadScript
            googleMapsApiKey="AIzaSyAeFfX_vyhGQXEQecDAkJ8gztyOWGJ-TBY"
        >
            <GoogleMap
                mapContainerStyle={{
                    width: '100%',
                    height: '255px'
                }}
                center={position}
                zoom={16}
            >
                <Marker
                    position={position}
                    icon={data.logo.childImageSharp.gatsbyImageData.src}
                />
                <Polygon
                    path={coords}
                    key={1}
                    options={{
                        fillColor: "#D75A27",
                        fillOpacity: 0.4,
                        strokeColor: "#D75A27",
                        strokeOpacity: 1,
                        strokeWeight: 1
                    }}
                />
            </GoogleMap>
        </LoadScript>
    );
}