import * as React from "react"
import Typography from "@mui/material/Typography";
import {StaticImage} from "gatsby-plugin-image";

const GevelaanzichtBlok6 = () => {
    return (
        <>
            <Typography
                variant={'h3'}
            >
                Blok 6
            </Typography>
            <StaticImage
                src={'../../images/gevelaanzichten/BLOK 6.png'}
                alt={'blok 6'}
            />
        </>
    )
}

export default GevelaanzichtBlok6